interface GTMAttributes {
  category?: string
  action?: string
  label?: string
}

declare global {
  interface Window {
    setGTM: (el: Element, gtm: GTMAttributes) => void
  }
}

const logGTM = (element: Element) => {
  const trigger = element.closest('[data-gtm-action]')
  if (trigger) {
    const path = window.location.pathname.replace(/^\//, '')
    const category = trigger.getAttribute('data-gtm-category') || path
    const action = trigger.getAttribute('data-gtm-action')
    const label = trigger.getAttribute('data-gtm-label') || path
    const msg = Object.entries({ category, action, label }).filter(([ _, value ]) => value).map(([ key, value ]) => `${key}: ${value}`)
    console.log('[GTM]', msg.join(', '))
  }
}

const handleGTMClick = (event: Event) => {
  if (event instanceof PointerEvent && event.altKey) {
    event.preventDefault()
    event.stopPropagation()
  }
  if (event.target instanceof Element) {
    logGTM(event.target)
  }
}

export const addGTMLogger = () => {
  console.log('[GTM] init')
  document.removeEventListener('click', handleGTMClick)
  document.addEventListener('click', handleGTMClick)
}

export const setGTM = (el: Element, gtm: GTMAttributes) => {
  Object.entries(gtm).forEach(([ attr, value ]) => {
    attr = `data-gtm-${attr}`
    if (value) {
      el.setAttribute(attr, value)
    } else {
      el.removeAttribute(attr)
    }
  })
}

export const initGTM = () => {
  if (import.meta.env.DEV) {
    addGTMLogger()
  }
  window.setGTM = setGTM
}
