import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/vercel/path0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4 from "/vercel/path0/src/plugins/sentry.client.ts";
import onesignal_client_CCRRhde0ZEUvUmuBe_S8ux82_SXKNvbzHQWY0w907IA from "/vercel/path0/src/plugins/onesignal.client.ts";
import recharge_client_7Aszwx3tPQSHq_3VrI9nh4ykek7gnHUYSckd0njyGxA from "/vercel/path0/src/plugins/recharge.client.ts";
import app_client_EBW1i4Nd5iYRkIhiZ9Bkr0xcmUscQMQaFYal48HVrro from "/vercel/path0/src/plugins/app.client.ts";
import auth_client_L7VAFv17FbQ99higavHYAh50k2IuE44ZX__gSvOqqRY from "/vercel/path0/src/plugins/auth.client.ts";
import breakpoints_client_sNH_z69DGRszWUkzHuGT4otpO67VG6EFW_pqi6mv1xk from "/vercel/path0/src/plugins/breakpoints.client.ts";
import third_party_client_NurwdGglb3uCm8SZjxufD9RXEj8m_nr8fno_VoTvkCQ from "/vercel/path0/src/plugins/third-party.client.ts";
import vue_gtm_client_53EAuFqVjIgTAPQRhRYGtmEVkDPCJSyM4hMIBtn12_s from "/vercel/path0/src/plugins/vue-gtm.client.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4,
  onesignal_client_CCRRhde0ZEUvUmuBe_S8ux82_SXKNvbzHQWY0w907IA,
  recharge_client_7Aszwx3tPQSHq_3VrI9nh4ykek7gnHUYSckd0njyGxA,
  app_client_EBW1i4Nd5iYRkIhiZ9Bkr0xcmUscQMQaFYal48HVrro,
  auth_client_L7VAFv17FbQ99higavHYAh50k2IuE44ZX__gSvOqqRY,
  breakpoints_client_sNH_z69DGRszWUkzHuGT4otpO67VG6EFW_pqi6mv1xk,
  third_party_client_NurwdGglb3uCm8SZjxufD9RXEj8m_nr8fno_VoTvkCQ,
  vue_gtm_client_53EAuFqVjIgTAPQRhRYGtmEVkDPCJSyM4hMIBtn12_s
]