import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ vueApp: app, $config: { public: { shopifyUrl } } }) => {
  window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault()
    console.warn('Unhandled rejection:', event.reason)

    // const error = new Error('unhandledrejection')
    // error.cause = event.reason
    // Preserving Sentry bandwidth from unhandled rejection errors

    // To turn on Sentry for unhandled rejection errors, uncomment the following line
    // Sentry.captureException(error)
  })

  return {
    provide: {
      sentry: Sentry
    }
  }
})
