import { globalComponentsPlugin } from '@oatsovernight/storybook'
import { initGTM } from '~/services/gtm-logger'

export default defineNuxtPlugin(async ({ vueApp }) => {
  vueApp.use(globalComponentsPlugin)

  const app = useAppStore()
  const toast = useToastStore()

  window.addEventListener('vite:preloadError', clearCaches)
  initGTM()

  await app.init()

  return {
    provide: {
      app,
      toast
    }
  }
})
