<template>
  <VitePwaManifest />
  <HeadMeta />
  <NuxtLayout>
    <ContentRefresh />
    <SectionLoader
      v-if="$auth?.loading"
      class="min-h-[260px]"
    />
    <WelcomeScreen v-else-if="$app.showWelcomeScreen && !$auth.authenticated" />
    <ErrorBoundary @error="sendToSentry">
      <NuxtPage />
      <template #error="{ error, clearError }">
        <Error
          v-bind="{ error }"
          @reset="clearError"
        />
      </template>
    </ErrorBoundary>
  </NuxtLayout>
  <ToastAlert />
  <NotificationPrompt v-if="!isNativeApp && settings.features.pushNotifications && $auth.authenticated" />
  <HalfSheet
    v-if="$app.sheet.content"
    :visible="$app.sheet.visible"
    :content="$app.sheet.content"
    @close="$app.sheet.close()"
  />
  <ModalDialog
    v-if="!$app.modal.stack && $app.modal.content"
    :visible="$app.modal.visible"
    :content="$app.modal.content"
    @close="$app.modal.close()"
  />
  <BlockingLoader
    :blocking="$app.blocking"
    :blocking-label="$app.blockingLabel"
  />
</template>

<script setup lang="ts">
import 'virtual:svg-icons-register'

import {
  ModalDialog,
  HalfSheet,
  BlockingLoader
} from '@oatsovernight/storybook'

import { detectPwaInstallation } from '@/services/gtm'

const settings = useSettingsStore()

detectPwaInstallation()
</script>
