import { createGtm, useGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(({ vueApp: app }) => {
  const { public: { gtm: { id, enabled } } } = useRuntimeConfig()

  app.use(createGtm({
    id: id,
    enabled: Boolean(enabled)
  }))

  return {
    provide: {
      gtm: useGtm()
    }
  }
})
