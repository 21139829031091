import { initRecharge } from '@rechargeapps/storefront-client'
import { useAuthStore } from '@/stores/auth'

const TOKEN_STORAGE_KEY = 'rechargeTokenIndex'

export default defineNuxtPlugin(({ $config: { public: { shopifyUrl, rechargeStorefrontToken } } }) => {
  const TOKENS = rechargeStorefrontToken.split(',')

  const getNextStorefrontToken = () => {
    const currentTokenIndex = Number(localStorage.getItem(TOKEN_STORAGE_KEY)) || 0
    localStorage.setItem(TOKEN_STORAGE_KEY, String((currentTokenIndex + 1) % (TOKENS?.length || 1)))
    return TOKENS?.[currentTokenIndex]
  }

  initRecharge({
    storeIdentifier: shopifyUrl,
    storefrontAccessToken: getNextStorefrontToken(),
    loginRetryFn: async () => {
      const { refreshSession } = useAuthStore()
      const session = await refreshSession()
      if (session) {
        return session
      }
    }
  })
})
