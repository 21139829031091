import { useOneSignal } from '@onesignal/onesignal-vue3'

export default defineNuxtPlugin(({ $config }) => {
  const { onesignalAppId, onesignalSafariWebId } = $config.public

  if (!onesignalAppId || isNativeApp) {
    return
  }

  const oneSignal = useOneSignal() as ReturnType<typeof useOneSignal> & { initialized: Promise<boolean> }

  oneSignal.initialized = new Promise((resolve) => {
    oneSignal.init({
      allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
      appId: onesignalAppId,
      serviceWorkerParam: { scope: '/' },
      serviceWorkerPath: '/onesignal-worker.js',
      safari_web_id: onesignalSafariWebId
    })
      .then(() => resolve(true))
      .catch(() => resolve(false))
  })

  return {
    provide: { oneSignal }
  }
})
