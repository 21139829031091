import { ImageFragment, metaImage } from './images'

export const ProductVariantFragment = `id
sku
title
availableForSale
quantityAvailable
image {
  ${ImageFragment()}
}
price {
  amount
  currencyCode
}`

export const ProductFragment = (variantCount = 1) => `id
handle
title
description
descriptionHtml
productType
tags
priceRange {
  minVariantPrice {
    amount
    currencyCode
  }
}
images(first: 5) {
  edges {
    node {
      ${ImageFragment()}
    }
  }
}
featuredImage {
  ${ImageFragment(640)}
}
${ProductMetaFieldsFragment}
variants(first: ${variantCount}) {
  edges {
    node {
      ${ProductVariantFragment}
    }
  }
}`

export const BundleParentProductFragment = `id
handle
title
variants(first: 4) {
  edges {
    node {
      ${ProductVariantFragment}
    }
  }
}
sellingPlanGroups(first: 1) {
  edges {
    node {
      sellingPlans(first: 1) {
        edges {
          node {
            id
            name
            description
            recurringDeliveries
            options {
              name
              value
            }
          }
        }
      }
    }
  }
}
`

export const ProductMetaFieldsFragment = `
ratingAverage: metafield(namespace: "flavor", key: "junip_rating_average") {
  value
}
ratingCount: metafield(namespace: "flavor", key: "junip_rating_count") {
  value
}
protein: metafield(namespace: "flavor", key: "protein") {
  value
}
calories: metafield(namespace: "flavor", key: "calories") {
  value
}
nutrition: metafield(namespace: "flavor", key: "nutrition_facts") {
  value
}
ingredients: metafield(namespace: "flavor", key: "ingredients") {
  value
}
bgColor: metafield(namespace: "flavor", key: "nutrition_background_color") {
  value
}
zoomImage: ${metaImage('zoom_image', 1542)}
flavorForwardImage: ${metaImage('flavor_forward_image', 1000)}
nutritionFlavorForwardImage: ${metaImage('nutrition_flavor_forward_image', 1000)}
nutritionImage: ${metaImage('nutrition_image', 768)}`

export const listProductMeta = () => `shortTitle: metafield(namespace: "descriptors", key: "short_title") {
  value
}
sugar: metafield(namespace: "flavor", key: "sugar") {
  value
}
bgColor: metafield(namespace: "flavor", key: "nutrition_background_color") {
  value
}
${ProductMetaFieldsFragment}
bundleProduct: ${metaBundleProduct()}
profileImage: ${metaImage('profile_image', 400)}
nutritionFlavorForwardImage: ${metaImage('nutrition_flavor_forward_image', 1000)}
nutritionImage: ${metaImage('nutrition_image')}`

export const metaBundleProduct = () => `metafield(namespace: "flavor", key: "bundle_product") {
  reference {
    ...on Product {
      id
      variants(first: 1) {
        edges {
          node {
            id
            sku
          }
        }
      }
    }
  }
}`
