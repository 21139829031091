export const DOMAIN = String(import.meta.env.VITE_SHOPIFY_URL)
export const SHOPIFY_STORE = DOMAIN.replace('.myshopify.com', '')
export const WEBSITE_DOMAIN = SHOPIFY_STORE === 'oats-3' ? 'oatsovernight.com' : DOMAIN
export const BUNDLE_COLLECTION_ID = String(import.meta.env.VITE_BUNDLER_COLLECTION_ID)
export const COFFEE_BUNDLE_COLLECTION_ID = String(import.meta.env.VITE_COFFEE_BUNDLE_COLLECTION_ID)
export const CUSTOMER_PRODUCTS_COLLECTION_ID = String(import.meta.env.VITE_CUSTOMER_PRODUCTS_COLLECTION_ID)

export const MAX_BLENDERBOTTLE_QUANTITY = 5
export const BLENDERBOTTLE_PRICE = 6
export const BUNDLE_SKU = /^(SUB-)?(COFFEE|CUSTOM)/i
export const OATMEAL_BUNDLE_SKU = /^(SUB-)?CUSTOM/i
export const COFFEE_BUNDLE_SKU = /^(SUB-)?COFFEE/i
export const BUNDLE_ITEM_SKU = /(40|44)-\d+/i
export const COFFEE_ITEM_SKU = /44-\d+/i
export const PID_SKU = /(41|43)-\d+/i
export const BOTTLE_SKU = /(50-(10[034]))|(D?55(-FREE)?)/i
export const UPGRADABLE_BUNDLE_SIZES = [ 8, 16, 24 ]
export const EMAIL_REGEX = /.+@.+\..+/

export const BUNDLE_FILTERS = {
  'all': 'All',
  'fruit-flavor': 'Fruit',
  'caffeinated': 'Caffeinated',
  'vegan': 'Vegan',
  'whey-protein': 'Whey',
  'subscribers-only': 'Subscribers Only'
}

export const BUNDLE_SORTS = {
  'default': 'Default',
  'a-z': 'A-Z',
  'z-a': 'Z-A',
  'release-date': 'Release Date',
  'favorites': 'Favorites',
  'rating': 'Rating'
}

export const TAGS = {
  PORTAL_LOGIN: 'portal-login',
  PWA_LOGIN: 'pwa-login'
}
