<!-- idea: allow a user to re-toggle the modal from the sidebar or header if they clicked dismiss -->

<!-- <template>
  <button @click="showNotificationPrompt">
    <SvgIcon
      name="bell"
      class="w-4 h-4"
    />
  </button>
</template> -->

<template>
  <slot :show-notification-prompt="showNotificationPrompt" />
</template>

<script lang="ts" setup>
import { differenceInDays } from 'date-fns'
import { get } from '@/utils/storage'

const { $app, $oneSignal: { User, Notifications } } = useNuxtApp()
const NotificationPromptModal = () => import('./NotificationPromptModal.vue')

const notificationPrompted = new Date(get('notification-prompt') || 0)
const showNotificationFeature = computed(() => {
  const { permission, permissionNative } = Notifications
  return !permission
    && permissionNative !== 'denied'
    && differenceInDays(new Date(), notificationPrompted) > 7
})

async function notificationPermissionChange (permission: boolean) {
  if (permission) {
    await User.PushSubscription.optIn()
  }
}

const showNotificationPrompt = () => {
  $app.modal.open(NotificationPromptModal, undefined, {
    accept: () => {
      Notifications.requestPermission()
      $app.modal.close()
    }
  })
}

onMounted(() => {
  if (showNotificationFeature.value) {
    setTimeout(showNotificationPrompt, 2000)
  }
})

onBeforeMount(() => {
  Notifications.addEventListener('permissionChange', notificationPermissionChange)
})
onBeforeUnmount(() => {
  Notifications.removeEventListener('permissionChange', notificationPermissionChange)
})
</script>
