export const get = (key: string) => {
  const value = window.localStorage.getItem(key)
  try {
    if (typeof value === 'string') {
      return JSON.parse(value)
    }
  } catch {
    return value
  }
}

export const set = (key: string, value: string | number | boolean | object) => {
  window.localStorage.setItem(key, JSON.stringify(value))
  return value
}

export const del = (key: string) => window.localStorage.removeItem(key)

export default { get, set }
