import { isNativeApp } from './app'

export const useDeviceDetection = () => {
  if (typeof window === 'undefined') return {
    isIos: false,
    isStandalone: false,
    isNativeApp: false
  }

  const isIos = /iphone|ipod|ipad/i.test(navigator.userAgent)
  const isStandalone = import.meta.browser && (navigator as any).standalone

  return {
    isIos,
    isStandalone,
    isNativeApp
  }
}
