import { useGtm } from '@gtm-support/vue-gtm'
import type { Subscription } from '@rechargeapps/storefront-client'
import { useDeviceDetection } from '@/utils/device'

export type SubscriptionEventType = 'create' | 'update' | 'cancel' | 'delay'

export interface SubscriptionTrackingOptions {
  changedFields?: string[]
  cancellationReason?: string
  delayPeriod?: string
}

export const trackPwaInstallation = (label: string) => {
  const gtm = useGtm()
  gtm?.trackEvent({
    event: 'pwa_installation',
    category: 'PWA',
    action: 'installation',
    label
  })
}

export const detectPwaInstallation = () => {
  if (!window) {
    return
  }

  // Android installation detection
  window.addEventListener('appinstalled', () => trackPwaInstallation('Android'))

  // iOS installation detection
  const { isIos, isStandalone, isNativeApp } = useDeviceDetection()
  const isInstalled = !localStorage.getItem('pwa_installed')
  if (isIos && isStandalone && isInstalled && !isNativeApp) {
    trackPwaInstallation('iOS')
    localStorage.setItem('pwa_installed', 'true')
  }
}

export const trackSubscriptionEvent = (
  eventType: SubscriptionEventType,
  subscription: Subscription,
  options: SubscriptionTrackingOptions = {}
) => {
  const gtm = useGtm()

  const commonData = {
    event: `subscription_${eventType}`,
    sku: subscription.sku,
    subscription_type: String(subscription.sku).includes('COFFEE') ? 'coffee' : 'oatmeal',
    title: subscription.product_title,
    variant: subscription.variant_title,
    frequency: `${subscription.order_interval_frequency} ${subscription.order_interval_unit}`
  }

  let eventData = {}

  switch (eventType) {
  case 'update':
    if (!options.changedFields || options.changedFields.length === 0) {
      console.warn('No changedFields provided for subscription update tracking')
    }
    eventData = {
      updated_fields: options.changedFields?.join(', ') || 'unknown fields'
    }
    break

  case 'cancel':
    eventData = {
      cancel_reason: options.cancellationReason || 'no reason provided'
    }
    break

  case 'delay':
    eventData = {
      delay_period: options.delayPeriod
    }
    break
  }

  gtm?.trackEvent({
    ...commonData,
    ...eventData
  })
}

export const trackSubscriptionCreate = (
  subscription: Subscription
) => {
  trackSubscriptionEvent('create', subscription)
}

export const trackSubscriptionUpdate = (
  subscription: Subscription,
  changedFields: string[]
) => {
  trackSubscriptionEvent('update', subscription, { changedFields })
}

export const trackSubscriptionCancel = (
  subscription: Subscription,
  cancellationReason: string
) => {
  trackSubscriptionEvent('cancel', subscription, { cancellationReason })
}

export const trackSubscriptionDelay = (
  subscription: Subscription,
  delayPeriod: string
) => {
  trackSubscriptionEvent('delay', subscription, { delayPeriod })
}
