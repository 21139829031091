import { gidToId } from '@/utils/graphql'
import type { Customer } from '~/types/shopify'

const log = logger('OneSignal')

function tagFilter (tag: string) {
  const _tag = tag.toLowerCase()

  return !_tag.startsWith('fid=')
    && !_tag.startsWith('shipping=')
    && !_tag.endsWith('front end bundler')
    && _tag !== 'shop'
    && _tag !== 'first box discount'
    && _tag !== 'portal-login'
    && _tag !== 'pwa-login'
    && _tag !== 'login with shop'
}

// OneSignal expects tags as {TAG_KEY: TAG_VALUE}
function shopifyTagsToOneSignal (tags: string[], limit?: number): Record<string, string> {
  const tagsMap: Record<string, string> = {}
  let processed = 0

  for (let i = 0; i < tags.length; ++i) {
    const tag = tags[i]
    if (tagFilter(tag)) {
      tagsMap[tag] = tag
      if (limit && ++processed === limit) break
    }
  }

  return tagsMap
}

export async function oneSignalLogin (customer: Customer) {
  const { $oneSignal: { login, User } } = useNuxtApp()
  const { id, email, phone, tags } = customer
  try {
    await login(String(gidToId(id)))
    User.addEmail(email)
    if (phone) {
      User.addSms(phone)
    }
    User.addTags(shopifyTagsToOneSignal(tags, 10))
  } catch (e) {
    log('Error logging into OneSignal', e)
    sendToSentry(e)
  }
}
