
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as betadM3L_45gbo9L2lOSSlm_45wp_M0P1l1d7pXApGv7XYaCT9MMeta } from "/vercel/path0/src/pages/beta.vue?macro=true";
import { default as indexjXfg20vSWiqV3Wel4ayAAWoFt0maAgUV4XGStNqb8sIMeta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as loginKTxJR1P_Tc_JAMSmBPkwIfzNI6fMEfkFhNMjeEfpQK4Meta } from "/vercel/path0/src/pages/login.vue?macro=true";
import { default as logoutcGZl_i_VC0fX7pCef8JasSzS7uNn4w0WNcdYX8UDYzoMeta } from "/vercel/path0/src/pages/logout.vue?macro=true";
import { default as signupu2nrUpTphsYEUdqYTuoTyqQFmE09dPd7et5xBnLcGb0Meta } from "/vercel/path0/src/pages/signup.vue?macro=true";
import { default as gifting4MEdAJT7a9Ue4tkcgGND4Dhue4qIwmuLfVi7bigZyvwMeta } from "/vercel/path0/src/pages/gifting.vue?macro=true";
import { default as activateaLnFl8pnpg_Gr4FzUmgcTYlvI2HD_45XawF0uuduyv3TwMeta } from "/vercel/path0/src/pages/activate.vue?macro=true";
import { default as favoritesvgZOPUp5Y7WUKpiqZec_45Rup6ygPeZsY598izs3F9UjoMeta } from "/vercel/path0/src/pages/favorites.vue?macro=true";
import { default as referralsw68j_45BW4qbER_ZDwUp47_45FGnaSfotNbxL8dNy5uaKz8Meta } from "/vercel/path0/src/pages/referrals.vue?macro=true";
import { default as indexP9MogACCyE0c_yVr2fzPjYcEu38hLRkySVvoBdJ13JQMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as loginDp4Bleal2_EehW1_v8EU_FNK2TAzA8k6mOlFU5iOA8kMeta } from "/vercel/path0/src/pages/admin/login.vue?macro=true";
import { default as editdCp0rm6z4NzZvd8pVOhpNP4qjFnpcQquFLvJG_45avrHEMeta } from "/vercel/path0/src/pages/account/edit.vue?macro=true";
import { default as indexMAQU1bHhj9FrpHTjHEgZKsJAZZe7uDWVSbvh2x44ieoMeta } from "/vercel/path0/src/pages/orders/index.vue?macro=true";
import { default as indexG7ItjoYNZ5TWa4BXYuejjXHq0lyWlCKwD7OAwDfiP4AMeta } from "/vercel/path0/src/pages/account/index.vue?macro=true";
import { default as customer8Oc1rmOL9DA_45Rzj3yrKrZ6wavzlUJVyWrlgYFw9wRhoMeta } from "/vercel/path0/src/pages/admin/customer.vue?macro=true";
import { default as indexIcUxtwbAiwl7cYVkZSGNxiZol3IugbjwGtAy703VnPYMeta } from "/vercel/path0/src/pages/products/index.vue?macro=true";
import { default as _91key_93yezdV75QeSOH9Yh54U8Mdn1cnZRk4WaIMCa0BobclM4Meta } from "/vercel/path0/src/pages/orders/[id]/[key].vue?macro=true";
import { default as set_45passwordmavtE_45jAdwIigQP84CBYeeFhH_LYt0w81Ijur_45iZNoUMeta } from "/vercel/path0/src/pages/admin/set-password.vue?macro=true";
import { default as edithLlt3ER_455Bpbq11vGpKC6xdLa8b90F8YyCOHAmzEJCAMeta } from "/vercel/path0/src/pages/onetimes/[id]/edit.vue?macro=true";
import { default as indexpZtXafb40SOid8GHYNA0cyZgJRCgw6rYDk9Wt263dnAMeta } from "/vercel/path0/src/pages/onetimes/[id]/index.vue?macro=true";
import { default as indexmCvujUIc4lO6nZNGlw1BYICn8TBs0A50Roo_45uGtNqX0Meta } from "/vercel/path0/src/pages/subscriptions/index.vue?macro=true";
import { default as merchandiselgsUs7zCA0ecug4O7i_4PzDqZrjFvux6LCYenf9EN14Meta } from "/vercel/path0/src/pages/products/merchandise.vue?macro=true";
import { default as list_45upgradezTJJ9PtjDbRoR3m_45oeWOLpvLmEAkBaoHDoXEq1NZDYsMeta } from "/vercel/path0/src/pages/upgrade/list-upgrade.vue?macro=true";
import { default as email_45sent3R8o9B9gPJk50qeoRD0O9_45luazgCh51kugS4SmJwqF8Meta } from "/vercel/path0/src/pages/reactivate/email-sent.vue?macro=true";
import { default as list_45upgradesZB0lwC0bnlb9AHy5pXmXV7GZ10EwzvNe_G2yROO8qA4Meta } from "/vercel/path0/src/pages/upgrade/list-upgrades.vue?macro=true";
import { default as variety_45packs4mt6CU0Hopa5dMq7N9dCjeZn9TeS9bHV13896gLHYBAMeta } from "/vercel/path0/src/pages/products/variety-packs.vue?macro=true";
import { default as perform_45upgradevU4QyRQaOkrnAPYwHj9S0k3oiW6ZGHvQAR0yEXHjtNYMeta } from "/vercel/path0/src/pages/upgrade/perform-upgrade.vue?macro=true";
import { default as indexNowKjpI97IBzKPEzcoFAJgsqhMb5U9rEQQO9FDAWM7QMeta } from "/vercel/path0/src/pages/subscriptions/[id]/index.vue?macro=true";
import { default as newZB2b_45j47_i3I2Fsq_62ETIxfR1hKxuzXNoO4soCAUsMMeta } from "/vercel/path0/src/pages/subscriptions/coffee/new.vue?macro=true";
import { default as new_0A9BQjbPWlfZPvyaaTDnxX1b3rdIvWN2BPlKwzffEgMeta } from "/vercel/path0/src/pages/subscriptions/oatmeal/new.vue?macro=true";
import { default as list_45reactivatemqJZNTZDajYhawtKj6PWQRg4e3ZUM7H3abMxWsk2tJIMeta } from "/vercel/path0/src/pages/reactivate/list-reactivate.vue?macro=true";
import { default as addressqYJ4MBulZZliKSYwPRhC5zapJh9c64edwBzcXO_4577acMeta } from "/vercel/path0/src/pages/subscriptions/[id]/address.vue?macro=true";
import { default as newzP3froNddpK_45S_7Z7J05wrbRlbrCOz4ntpYFqvIC0ZAMeta } from "/vercel/path0/src/pages/account/payment-methods/new.vue?macro=true";
import { default as editb40tJ9scV5m7a1AP_45jNl9jGRrRXyzV1Jq9Hj3QrD9GEMeta } from "/vercel/path0/src/pages/subscriptions/coffee/[id]/edit.vue?macro=true";
import { default as perform_45reactivation2WYkCvWpVIcAaf5j9xu_45e_455QzBDKQE5_457cv542mOfB4Meta } from "/vercel/path0/src/pages/reactivate/perform-reactivation.vue?macro=true";
import { default as editMDXxfG3GXdCJjJoWttPpVSxns79th7LZJ7pYAmyzibUMeta } from "/vercel/path0/src/pages/subscriptions/oatmeal/[id]/edit.vue?macro=true";
import { default as editHR6ePUzsNA_45hwED5BlCmV8EXMdaOJsVmqWNX0ms3hKAMeta } from "/vercel/path0/src/pages/account/payment-methods/[id]/edit.vue?macro=true";
export default [
  {
    name: "beta",
    path: "/beta",
    component: () => import("/vercel/path0/src/pages/beta.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginKTxJR1P_Tc_JAMSmBPkwIfzNI6fMEfkFhNMjeEfpQK4Meta || {},
    component: () => import("/vercel/path0/src/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutcGZl_i_VC0fX7pCef8JasSzS7uNn4w0WNcdYX8UDYzoMeta || {},
    component: () => import("/vercel/path0/src/pages/logout.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupu2nrUpTphsYEUdqYTuoTyqQFmE09dPd7et5xBnLcGb0Meta || {},
    component: () => import("/vercel/path0/src/pages/signup.vue")
  },
  {
    name: "gifting",
    path: "/gifting",
    component: () => import("/vercel/path0/src/pages/gifting.vue")
  },
  {
    name: "activate",
    path: "/activate",
    meta: activateaLnFl8pnpg_Gr4FzUmgcTYlvI2HD_45XawF0uuduyv3TwMeta || {},
    component: () => import("/vercel/path0/src/pages/activate.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/vercel/path0/src/pages/favorites.vue")
  },
  {
    name: "referrals",
    path: "/referrals",
    component: () => import("/vercel/path0/src/pages/referrals.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexP9MogACCyE0c_yVr2fzPjYcEu38hLRkySVvoBdJ13JQMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginDp4Bleal2_EehW1_v8EU_FNK2TAzA8k6mOlFU5iOA8kMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/login.vue")
  },
  {
    name: "account-edit",
    path: "/account/edit",
    component: () => import("/vercel/path0/src/pages/account/edit.vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/src/pages/orders/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/src/pages/account/index.vue")
  },
  {
    name: "admin-customer",
    path: "/admin/customer",
    meta: customer8Oc1rmOL9DA_45Rzj3yrKrZ6wavzlUJVyWrlgYFw9wRhoMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/customer.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/vercel/path0/src/pages/products/index.vue")
  },
  {
    name: "orders-id-key",
    path: "/orders/:id()/:key()",
    component: () => import("/vercel/path0/src/pages/orders/[id]/[key].vue")
  },
  {
    name: "admin-set-password",
    path: "/admin/set-password",
    meta: set_45passwordmavtE_45jAdwIigQP84CBYeeFhH_LYt0w81Ijur_45iZNoUMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/set-password.vue")
  },
  {
    name: "onetimes-id-edit",
    path: "/onetimes/:id()/edit",
    component: () => import("/vercel/path0/src/pages/onetimes/[id]/edit.vue")
  },
  {
    name: "onetimes-id",
    path: "/onetimes/:id()",
    component: () => import("/vercel/path0/src/pages/onetimes/[id]/index.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/vercel/path0/src/pages/subscriptions/index.vue")
  },
  {
    name: "products-merchandise",
    path: "/products/merchandise",
    component: () => import("/vercel/path0/src/pages/products/merchandise.vue")
  },
  {
    name: "upgrade-list-upgrade",
    path: "/upgrade/:email/:rcCustomerId/:rcCustomerHash/:subscriptionId",
    meta: list_45upgradezTJJ9PtjDbRoR3m_45oeWOLpvLmEAkBaoHDoXEq1NZDYsMeta || {},
    component: () => import("/vercel/path0/src/pages/upgrade/list-upgrade.vue")
  },
  {
    name: "reactivate-email-sent",
    path: "/email-sent/:type",
    meta: email_45sent3R8o9B9gPJk50qeoRD0O9_45luazgCh51kugS4SmJwqF8Meta || {},
    component: () => import("/vercel/path0/src/pages/reactivate/email-sent.vue")
  },
  {
    name: "upgrade-list-upgrades",
    path: "/upgrade/:email/:rcCustomerId/:rcCustomerHash/",
    meta: list_45upgradesZB0lwC0bnlb9AHy5pXmXV7GZ10EwzvNe_G2yROO8qA4Meta || {},
    component: () => import("/vercel/path0/src/pages/upgrade/list-upgrades.vue")
  },
  {
    name: "products-variety-packs",
    path: "/products/variety-packs",
    component: () => import("/vercel/path0/src/pages/products/variety-packs.vue")
  },
  {
    name: "upgrade-perform-upgrade",
    path: "/upgrade/perform-upgrade/:token/",
    meta: perform_45upgradevU4QyRQaOkrnAPYwHj9S0k3oiW6ZGHvQAR0yEXHjtNYMeta || {},
    component: () => import("/vercel/path0/src/pages/upgrade/perform-upgrade.vue")
  },
  {
    name: "subscriptions-id",
    path: "/subscriptions/:id()",
    component: () => import("/vercel/path0/src/pages/subscriptions/[id]/index.vue")
  },
  {
    name: "subscriptions-coffee-new",
    path: "/subscriptions/coffee/new",
    component: () => import("/vercel/path0/src/pages/subscriptions/coffee/new.vue")
  },
  {
    name: "subscriptions-oatmeal-new",
    path: "/subscriptions/oatmeal/new",
    component: () => import("/vercel/path0/src/pages/subscriptions/oatmeal/new.vue")
  },
  {
    name: "reactivate-list-reactivate",
    path: "/reactivate/:email/:rcCustomerId/:rcCustomerHash/",
    meta: list_45reactivatemqJZNTZDajYhawtKj6PWQRg4e3ZUM7H3abMxWsk2tJIMeta || {},
    component: () => import("/vercel/path0/src/pages/reactivate/list-reactivate.vue")
  },
  {
    name: "subscriptions-id-address",
    path: "/subscriptions/:id()/address",
    component: () => import("/vercel/path0/src/pages/subscriptions/[id]/address.vue")
  },
  {
    name: "account-payment-methods-new",
    path: "/account/payment-methods/new",
    component: () => import("/vercel/path0/src/pages/account/payment-methods/new.vue")
  },
  {
    name: "subscriptions-coffee-id-edit",
    path: "/subscriptions/coffee/:id()/edit",
    component: () => import("/vercel/path0/src/pages/subscriptions/coffee/[id]/edit.vue")
  },
  {
    name: "reactivate-perform-reactivation",
    path: "/reactivate/perform-reactivation/:token/",
    meta: perform_45reactivation2WYkCvWpVIcAaf5j9xu_45e_455QzBDKQE5_457cv542mOfB4Meta || {},
    component: () => import("/vercel/path0/src/pages/reactivate/perform-reactivation.vue")
  },
  {
    name: "subscriptions-oatmeal-id-edit",
    path: "/subscriptions/oatmeal/:id()/edit",
    component: () => import("/vercel/path0/src/pages/subscriptions/oatmeal/[id]/edit.vue")
  },
  {
    name: "account-payment-methods-id-edit",
    path: "/account/payment-methods/:id()/edit",
    component: () => import("/vercel/path0/src/pages/account/payment-methods/[id]/edit.vue")
  }
]